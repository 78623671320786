import Vue from "vue";
import VueRouter from "vue-router";
//import { screenAuth } from "../assets/scripts/js/ScreenAuth";
import { appConfig } from "../assets/scripts/js/AppConfig";
import { getParameter } from "../assets/scripts/js/GetParameter";

Vue.use(VueRouter);

const routes = [
  // ログイン
  {
    path: "/",
    alias: "/login",
    name: "Login",
    meta: {
      isPublic: true,
      screenId: appConfig.SCREEN_ID.P_COM_001,
    },
    component: () => import(/* webpackChunkName: "login" */ "../views/login.vue"),
  },
  // ０．ホーム
  {
    meta: {
      screenId: appConfig.MENU_ID.M_HOM,
    },
    path: "/" + appConfig.MENU_ID.M_HOM, //"/Home",
    name: appConfig.MENU_ID.M_HOM,
    component: () => import(/* webpackChunkName: "Home" */ "../views/common/Home.vue"),
  },

  // ０－１．マスタ初期導入取引先一覧
  {
    meta: {
      screenId: appConfig.MENU_ID.M_INI_CLIENT_LIST,
    },
    path: "/" + appConfig.MENU_ID.M_INI_CLIENT_LIST, //"/ini_client_list",
    name: appConfig.MENU_ID.M_INI_CLIENT_LIST,
    component: () =>
      import(/* webpackChunkName: "ini_client_list" */ "../views/initial/masterIniClientList.vue"),
  },

  // ０－１．モジュール選択
  {
    meta: {
      screenId: appConfig.MENU_ID.M_INI_MODULE_SELECT,
    },
    path: "/" + appConfig.MENU_ID.M_INI_MODULE_SELECT, //"/ini_module_setting",
    name: appConfig.MENU_ID.M_INI_MODULE_SELECT,
    component: () =>
      import(/* webpackChunkName: "ini_module_setting" */ "../views/initial/moduleSelect.vue"),
  },
  // ０－２．マスタ登録
  {
    meta: {
      screenId: appConfig.MENU_ID.M_INI_MASTER_REGIST,
    },
    path: "/" + appConfig.MENU_ID.M_INI_MASTER_REGIST, //"/ini_master_regist",
    name: appConfig.MENU_ID.M_INI_MASTER_REGIST,
    component: () =>
      import(/* webpackChunkName: "ini_master_regist" */ "../views/initial/masterRegist.vue"),
  },
  // １－１．入荷予定登録
  {
    meta: {
      screenId: appConfig.SCREEN_ID.P_RCV_001,
    },
    path: "/" + appConfig.SCREEN_ID.P_RCV_001, //"/rcv_schedule_add",
    name: appConfig.SCREEN_ID.P_RCV_001, //"RcvScheduleAdd",
    component: () =>
      import(
        /* webpackChunkName: "rcv_schedule_add" */ "../views/receiving/ReceivingScheduleAdd.vue"
      ),
  },
  // １－２．入荷予定データ取込
  {
    meta: {
      screenId: appConfig.SCREEN_ID.P_RCV_002,
    },
    path: "/" + appConfig.SCREEN_ID.P_RCV_002, //"/rcv_data_import",
    name: appConfig.SCREEN_ID.P_RCV_002, //"RcvDataImport",
    component: () =>
      import(
        /* webpackChunkName: "rcv_data_import" */ "../views/receiving/ReceivingDataImport.vue"
      ),
  },
  // １－３．入荷予定差分取込
  {
    meta: {
      screenId: appConfig.SCREEN_ID.P_RCV_007,
    },
    path: "/" + appConfig.SCREEN_ID.P_RCV_007, //"/rcv_data_import",
    name: appConfig.SCREEN_ID.P_RCV_007, //"RcvDataImport",
    component: () =>
      import(
        /* webpackChunkName: "rcv_data_import" */ "../views/receiving/ReceivingDataDifference.vue"
      ),
  },
  // 入荷予定データ取込エラー確認
  {
    meta: {
      screenId: appConfig.SCREEN_ID.P_RCV_010,
    },
    path: "/" + appConfig.SCREEN_ID.P_RCV_010, //"/rcv_data_import_error",
    name: appConfig.SCREEN_ID.P_RCV_010, //"RcvDataImportError",
    component: () =>
      import(
        /* webpackChunkName: "rcv_data_import_error" */ "../views/receiving/ReceivingDataImportError.vue"
      ),
  },
  // １－３．入荷予定一覧
  {
    meta: {
      screenId: appConfig.SCREEN_ID.P_RCV_003,
    },
    path: "/" + appConfig.SCREEN_ID.P_RCV_003, // "/rcv_schedule_list",
    name: appConfig.SCREEN_ID.P_RCV_003, //"RcvScheduleList",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "rcv_schedule_list" */ "../views/receiving/ReceivingScheduleList.vue"
      ),
  },
  // 入荷予定修正削除
  {
    meta: {
      screenId: appConfig.SCREEN_ID.P_RCV_004,
    },
    path: "/" + appConfig.SCREEN_ID.P_RCV_004, //"/rcv_schedule_updatedelete",
    name: appConfig.SCREEN_ID.P_RCV_004, //"RcvScheduleUpdateDelete",
    component: () =>
      import(
        /* webpackChunkName: "rcv_schedule_updatedelete" */ "../views/receiving/ReceivingScheduleUpdateDelete.vue"
      ),
  },
  // ２－２．入荷実績報告
  {
    path: "/" + appConfig.SCREEN_ID.P_RCV_009, //"/rcv_achieve_report",
    name: appConfig.SCREEN_ID.P_RCV_009, //"RcvAchieveReport",
    meta: {
      screenId: appConfig.SCREEN_ID.P_RCV_009,
    },
    component: () =>
      import(
        /* webpackChunkName: "rcv_achieve_report" */ "../views/receiving/ReceivingAchieveReport.vue"
      ),
  },
  // // ２－３．入荷実績一覧????????????????????????????????????
  // {
  //   path: "/" + appConfig.MENU_ID.P_RCV_ACTUAL_LIST, //"/rcv_achieve_list",
  //   name: appConfig.MENU_ID.P_RCV_ACTUAL_LIST, //"RcvAchieveList",
  //   meta: {
  //     screenId: appConfig.SCREEN_ID.P_MNG_000,
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "rcv_achieve_list" */ "../views/receiving/ReceivingAchieveList.vue"
  //     ),
  // },
  // ３－１．入庫実績登録（検索）
  {
    path: "/" + appConfig.SCREEN_ID.P_ENT_001, //"/ent_achieve_report",
    name: appConfig.SCREEN_ID.P_ENT_001, //"EntAchieveReport",
    meta: {
      screenId: appConfig.SCREEN_ID.P_ENT_001,
    },
    component: () =>
      import(
        /* webpackChunkName: "ent_achieve_search" */ "../views/entering/EnteringAchieveSearch.vue"
      ),
  },
  // ３－２．入庫実績一覧????????????????????????????????????
  // {
  //   path: "/" + appConfig.MENU_ID.P_ENT_ACTUAL_LIST, //"/ent_achieve_list",
  //   name: appConfig.MENU_ID.P_ENT_ACTUAL_LIST, //"EntAchieveList",
  //   meta: {
  //     screenId: appConfig.SCREEN_ID.P_MNG_003,
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "ent_achieve_list" */ "../views/entering/EnteringAchieveList.vue"
  //     ),
  // },
  // ４－１．出荷予定登録
  {
    meta: {
      screenId: appConfig.SCREEN_ID.P_SHP_001,
    },
    path: "/" + appConfig.SCREEN_ID.P_SHP_001, //"/shp_schedule_add",
    name: appConfig.SCREEN_ID.P_SHP_001, //"ShpScheduleAdd",
    component: () =>
      import(/* webpackChunkName: "shp_schedule_add" */ "../views/ship/ShipScheduleAdd.vue"),
  },
  // ４－２．出荷予定データ取込
  {
    meta: {
      screenId: appConfig.SCREEN_ID.P_SHP_002,
    },
    path: "/" + appConfig.SCREEN_ID.P_SHP_002, //"/shp_data_import",
    name: appConfig.SCREEN_ID.P_SHP_002, //"ShpDataImport",
    component: () =>
      import(/* webpackChunkName: "shp_data_import" */ "../views/ship/ShipDataImport.vue"),
  },
  // ４－３．出荷予定差分取込
  {
    meta: {
      screenId: appConfig.SCREEN_ID.P_SHP_012,
    },
    path: "/" + appConfig.SCREEN_ID.P_SHP_012, //"/ship_data_difference",
    name: appConfig.SCREEN_ID.P_SHP_012, //"ShpDataDifference",
    component: () =>
      import(/* webpackChunkName: "shp_data_import" */ "../views/ship/ShipDataDifference.vue"),
  },
  // ４－３．出荷予定一覧
  {
    meta: {
      screenId: appConfig.SCREEN_ID.P_SHP_003,
    },
    path: "/" + appConfig.SCREEN_ID.P_SHP_003, //"/shp_schedule_list",
    name: appConfig.SCREEN_ID.P_SHP_003, //"ShpScheduleList",
    component: () =>
      import(/* webpackChunkName: "shp_schedule_list" */ "../views/ship/ShipScheduleList.vue"),
  },
  // ４－３．出荷予定一覧
  // {
  //   meta: {
  //     screenId: appConfig.SCREEN_ID.P_SHP_003,
  //   },
  //   path: "/" + appConfig.SCREEN_ID.P_SHP_003, //"/shp_schedule_list",
  //   name: appConfig.SCREEN_ID.P_SHP_003, //"ShpScheduleList",
  //   component: () =>
  //     import(/* webpackChunkName: "shp_schedule_list" */ "../views/ship/ShipScheduleList.vue"),
  // },
  // 出荷予定修正削除
  {
    path: "/" + appConfig.SCREEN_ID.P_SHP_004, //"/shp_schedule_updateDelete",
    name: appConfig.SCREEN_ID.P_SHP_004, //"ShpScheduleUpdateDelete",
    meta: {
      screenId: appConfig.SCREEN_ID.P_SHP_004,
    },
    component: () =>
      import(
        /* webpackChunkName: "shp_schedule_updateDelete" */ "../views/ship/ShipScheduleUpdateDelete.vue"
      ),
  },
  //４－４ 路線伝票
  {
    path: "/" + appConfig.SCREEN_ID.P_SHP_005,
    name: appConfig.SCREEN_ID.P_SHP_005,
    meta: {
      screenId: appConfig.SCREEN_ID.P_SHP_005,
    },
    component: () => import(/* webpackChunkName: "RouteSlip" */ "../views/route/RouteSlip.vue"),
  },
  //４－５ 路線伝票明細
  {
    path: "/" + appConfig.SCREEN_ID.P_SHP_007,
    name: appConfig.SCREEN_ID.P_SHP_007,
    meta: {
      screenId: appConfig.SCREEN_ID.P_SHP_007,
    },
    component: () =>
      import(/* webpackChunkName: "RouteSlipDetail" */ "../views/route/RouteSlipDetail.vue"),
  },
  //４－６．出荷予定CSV取込エラー確認
  {
    path: "/" + appConfig.SCREEN_ID.P_SHP_008, //"/shp_achieve_list",
    name: appConfig.SCREEN_ID.P_SHP_008, //"ShpAchieveList",
    meta: {
      screenId: appConfig.SCREEN_ID.P_SHP_008,
    },
    component: () =>
      import(/* webpackChunkName: "shp_data_import" */ "../views/ship/ShipDataImportCsvError.vue"),
  },
  // ４－７．号車登録
  {
    meta: {
      screenId: appConfig.SCREEN_ID.P_SHP_011,
    },
    path: "/" + appConfig.SCREEN_ID.P_SHP_011, //"/shp_schedule_add",
    name: appConfig.SCREEN_ID.P_SHP_011, //"ShpScheduleAdd",
    component: () =>
      import(/* webpackChunkName: "shp_schedule_add" */ "../views/ship/ShipTruckNumAdd.vue"),
  },
  // ５－１．一括引当
  {
    meta: {
      screenId: appConfig.SCREEN_ID.P_STK_001,
    },
    path: "/" + appConfig.SCREEN_ID.P_STK_001, //"/inventory_reservation",
    name: appConfig.SCREEN_ID.P_STK_001, //"InventoryReservation",
    component: () =>
      import(
        /* webpackChunkName: "inventory_reservation" */ "../views/stock/InventoryReservation.vue"
      ),
  },
  // ５－２．個別引当
  {
    path: "/" + appConfig.SCREEN_ID.P_STK_004, //"/reservation_Individual",
    name: appConfig.SCREEN_ID.P_STK_004, //"ReservationIndividual",
    meta: {
      screenId: appConfig.SCREEN_ID.P_STK_004,
    },
    component: () =>
      import(
        /* webpackChunkName: "reservation_Individual" */ "../views/stock/ReservationIndividual.vue"
      ),
  },
  // ５－３．引当結果取消
  {
    path: "/" + appConfig.SCREEN_ID.P_STK_002, //"/reservation_Confirm",
    name: appConfig.SCREEN_ID.P_STK_002, //"ReservationConfirm",
    meta: {
      screenId: appConfig.SCREEN_ID.P_STK_002,
    },
    component: () =>
      import(/* webpackChunkName: "reservation_Confirm" */ "../views/stock/ReservationConfirm.vue"),
  },
  // 引当登録
  {
    path: "/" + appConfig.SCREEN_ID.P_STK_005, //"/reservation_Confirm",
    name: appConfig.SCREEN_ID.P_STK_005, //"ReservationConfirm",
    meta: {
      screenId: appConfig.SCREEN_ID.P_STK_005,
    },
    component: () =>
      import(
        /* webpackChunkName: "reservation_Confirm" */ "../views/stock/ReservationRegister.vue"
      ),
  },
  // ５－１．出庫実績登録???????????????????????????????????????????????????
  {
    path: "/" + appConfig.SCREEN_ID.P_EXT_001, //"/ext_achieve_add",
    name: appConfig.SCREEN_ID.P_EXT_001, //"ExtAchieveAdd",
    meta: {
      screenId: appConfig.SCREEN_ID.P_EXT_001,
    },
    component: () =>
      import(/* webpackChunkName: "ext_achieve_add" */ "../views/exiting/ExitingAchieveAdd.vue"),
  },
  // // ５－２．出庫実績一覧??????????????????????????????????????????????????????????????
  // {
  //   path: "/" + appConfig.MENU_ID.P_EXT_ACTUAL_LIST, //"/ext_achieve_list",
  //   name: appConfig.MENU_ID.P_EXT_ACTUAL_LIST, //"ExtAchieveList",
  //   meta: {
  //     screenId: appConfig.SCREEN_ID.P_CNT_003,
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "ext_achieve_list" */ "../views/exiting/ExitingAchieveList.vue"),
  // },
  // ６－１．出荷実績登録
  {
    meta: {
      screenId: appConfig.SCREEN_ID.P_SHP_006,
    },
    path: "/" + appConfig.SCREEN_ID.P_SHP_006, //"/shp_achieve_add",
    name: appConfig.SCREEN_ID.P_SHP_006, //"ShpAchieveAdd",
    component: () =>
      import(/* webpackChunkName: "shp_achieve_add" */ "../views/ship/ShipAchieveAdd.vue"),
  },
  // ６－２．出荷実績報告
  {
    path: "/" + appConfig.SCREEN_ID.P_SHP_009, //"/shp_achieve_report",
    name: appConfig.SCREEN_ID.P_SHP_009, //"ShpAchieveReport",
    meta: {
      screenId: appConfig.SCREEN_ID.P_SHP_009,
    },
    component: () =>
      import(/* webpackChunkName: "ship_achieve_report" */ "../views/ship/ShipAchieveReport.vue"),
  },
  // // ６－３．出荷実績一覧????????????????????????????????????????????????????????
  // {
  //   path: "/" + appConfig.MENU_ID.P_SHP_ACTUAL_LIST, //"/shp_achieve_list",
  //   name: appConfig.MENU_ID.P_SHP_ACTUAL_LIST, //"ShpAchieveList",
  //   meta: {
  //     screenId: appConfig.SCREEN_ID.P_SHP_007,
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "shp_achieve_list" */ "../views/ship/ShipAchieveList.vue"),
  // },
  // ７－１．在庫一覧照会
  {
    path: "/" + appConfig.SCREEN_ID.P_SRG_001, //"/stk_inquiry",
    name: appConfig.SCREEN_ID.P_SRG_001, //"StkInquiry",
    meta: {
      screenId: appConfig.SCREEN_ID.P_SRG_001,
    },
    component: () =>
      import(/* webpackChunkName: "stk_inquiry" */ "../views/stock/StockInquiry.vue"),
  },
  // ７－２．在庫報告書
  {
    path: "/" + appConfig.SCREEN_ID.P_SRG_003,
    name: appConfig.SCREEN_ID.P_SRG_003,
    meta: {
      screenId: appConfig.SCREEN_ID.P_SRG_003,
    },
    component: () =>
      import(/* webpackChunkName: "stk_stock_report" */ "../views/stock/StockReport.vue"),
  },
  // ７－５．在庫情報変更指示一覧
  {
    path: "/" + appConfig.SCREEN_ID.P_STC_001,
    name: appConfig.SCREEN_ID.P_STC_001,
    meta: {
      screenId: appConfig.SCREEN_ID.P_STC_001,
    },
    component: () =>
      import(
        /* webpackChunkName: "stk_stock_update_instruction" */ "../views/stock/StockUpdateInstructionList.vue"
      ),
  },
  // ７－６．在庫情報変更指示
  {
    path: "/" + appConfig.SCREEN_ID.P_STC_002,
    name: appConfig.SCREEN_ID.P_STC_002,
    meta: {
      screenId: appConfig.SCREEN_ID.P_STC_002,
    },
    component: () =>
      import(
        /* webpackChunkName: "stk_stock_update_instruction" */ "../views/stock/StockUpdateInstruction.vue"
      ),
  },
  // ７－８．在庫情報変更指示登録
  {
    path: "/" + appConfig.SCREEN_ID.P_STC_003,
    name: appConfig.SCREEN_ID.P_STC_003,
    meta: {
      screenId: appConfig.SCREEN_ID.P_STC_003,
    },
    component: () =>
      import(
        /* webpackChunkName: "stk_stock_instruction_add" */ "../views/stock/StockUpdateInstructionAdd.vue"
      ),
  },
  // ７－９．不良・廃棄内容一覧
  {
    path: "/" + appConfig.SCREEN_ID.P_WST_001,
    name: appConfig.SCREEN_ID.P_WST_001,
    meta: {
      screenId: appConfig.SCREEN_ID.P_WST_001,
    },
    component: () =>
      import(
        /* webpackChunkName: "wst_defective_discarded_list" */ "../views/stock/DefectiveDiscardedList.vue"
      ),
  },
  // ７－１０．不良・廃棄内容詳細
  {
    path: "/" + appConfig.SCREEN_ID.P_WST_002,
    name: appConfig.SCREEN_ID.P_WST_002,
    meta: {
      screenId: appConfig.SCREEN_ID.P_WST_002,
    },
    component: () =>
      import(
        /* webpackChunkName: "wst_defective_discarded_list" */ "../views/stock/DefectiveDiscardedDetail.vue"
      ),
  },
  // ７－１１．荷主在庫比較
  {
    path: "/" + appConfig.SCREEN_ID.P_SRG_005,
    name: appConfig.SCREEN_ID.P_SRG_005,
    meta: {
      screenId: appConfig.SCREEN_ID.P_SRG_005,
    },
    component: () =>
      import(
        /* webpackChunkName: "stk_stock_update_instruction" */ "../views/stock/ReceivingStockCompare.vue"
      ),
  },
  // 取引先別在庫一覧照会
  {
    path: "/" + appConfig.SCREEN_ID.P_CLT_001,
    name: appConfig.SCREEN_ID.P_CLT_001,
    meta: {
      screenId: appConfig.SCREEN_ID.P_CLT_001,
    },
    component: () =>
      import(
        /* webpackChunkName: "stk_stock_inquiry_by_client" */ "../views/stock/StockInquiryByClient.vue"
      ),
  },
  // 取引先別在庫詳細照会
  {
    path: "/" + appConfig.SCREEN_ID.P_CLT_002,
    name: appConfig.SCREEN_ID.P_CLT_002,
    meta: {
      screenId: appConfig.SCREEN_ID.P_CLT_002,
    },
    component: () =>
      import(
        /* webpackChunkName: "stk_stock_inquiry_detail_by_client" */ "../views/stock/StockInquiryDetailByClient.vue"
      ),
  },
  // 在庫詳細
  {
    path: "/" + appConfig.SCREEN_ID.P_SRG_002, //"/stk_inquiry_details",
    name: appConfig.SCREEN_ID.P_SRG_002, //"StkInquiryDetails",
    meta: {
      screenId: appConfig.SCREEN_ID.P_SRG_002,
    },
    component: () =>
      import(
        /* webpackChunkName: "stk_inquiry_details" */ "../views/stock/StockInquiryDetails.vue"
      ),
  },
  // 在庫推移
  {
    path: "/" + appConfig.SCREEN_ID.P_SRG_004, //"/stock_transition",
    name: appConfig.SCREEN_ID.P_SRG_004, //"StockTransition",
    meta: {
      screenId: appConfig.SCREEN_ID.P_SRG_004,
    },
    component: () =>
      import(/* webpackChunkName: "stock_transition" */ "../views/stock/StockTransition.vue"),
  },
  // ８－１．はい替え指示登録（検索）
  {
    path: "/" + appConfig.SCREEN_ID.P_MOV_001, //"/mov_location_inst_Search",
    name: appConfig.SCREEN_ID.P_MOV_001, //"MovLocationInstSearch",
    meta: {
      screenId: appConfig.SCREEN_ID.P_MOV_001,
    },
    component: () =>
      import(
        /* webpackChunkName: "Mov_location_inst_Search" */ "../views/move/MoveLocationInstSearch.vue"
      ),
  },
  // はい替え指示登録
  {
    path: "/" + appConfig.SCREEN_ID.P_MOV_002, //"/mov_location_inst_add",
    name: appConfig.SCREEN_ID.P_MOV_002, //"MovLocationInstAdd",
    meta: {
      screenId: appConfig.SCREEN_ID.P_MOV_002,
    },
    component: () =>
      import(
        /* webpackChunkName: "mov_location_inst_add" */ "../views/move/MoveLocationInstAdd.vue"
      ),
  },
  // ８－２．はい替え表示一覧
  {
    path: "/" + appConfig.SCREEN_ID.P_MOV_003, //"/mov_location_inst_list",
    name: appConfig.SCREEN_ID.P_MOV_003, //"MovLocationInstList",
    meta: {
      screenId: appConfig.SCREEN_ID.P_MOV_003,
    },
    component: () =>
      import(
        /* webpackChunkName: "mov_location_inst_list" */ "../views/move/MoveLocationInstList.vue"
      ),
  },
  // はい替え実績登録（指示なし）????????????????????????????????
  {
    path: "/" + appConfig.SCREEN_ID.P_MOV_004, //"/mov_location_inst_list",
    name: appConfig.SCREEN_ID.P_MOV_004, //"MovLocationInstList",
    meta: {
      screenId: appConfig.SCREEN_ID.P_MNG_004,
    },
    component: () =>
      import(
        /* webpackChunkName: "mov_location_actual_add" */ "../views/move/MoveLocationAchieveAdd.vue"
      ),
  },
  // はい替え実績登録 (指示あり)
  {
    path: "/" + appConfig.SCREEN_ID.P_MOV_005, //"/mov_location_inst_list",
    name: appConfig.SCREEN_ID.P_MOV_005, //"MovLocationInstList",
    meta: {
      screenId: appConfig.SCREEN_ID.P_MNG_005,
    },
    component: () =>
      import(
        /* webpackChunkName: "mov_location_actual_add" */ "../views/move/MoveLocationAchieveAddIns.vue"
      ),
  },
  // ９－１．棚卸計画登録（検索）
  {
    path: "/" + appConfig.SCREEN_ID.P_CNT_001, //"/cnt_inst_search",
    name: appConfig.SCREEN_ID.P_CNT_001, //"CntInstSearch",
    meta: {
      screenId: appConfig.SCREEN_ID.P_CNT_001,
    },
    component: () =>
      import(/* webpackChunkName: "cnt_inst_search" */ "../views/count/CountInstSearch.vue"),
  },
  // 棚卸計画登録
  {
    path: "/" + appConfig.SCREEN_ID.P_CNT_002, //"/cnt_plan_add",
    name: appConfig.SCREEN_ID.P_CNT_002, // "CntPlanAdd",
    meta: {
      screenId: appConfig.SCREEN_ID.P_CNT_002,
    },
    component: () =>
      import(/* webpackChunkName: "cnt_plan_add" */ "../views/count/CountPlanAdd.vue"),
  },
  // ９－２．棚卸計画一覧
  {
    path: "/" + appConfig.SCREEN_ID.P_CNT_003, //"/cnt_plan_list",
    name: appConfig.SCREEN_ID.P_CNT_003, //"CntPlanList",
    meta: {
      screenId: appConfig.SCREEN_ID.P_CNT_003,
    },
    component: () =>
      import(/* webpackChunkName: "cnt_plan_list" */ "../views/count/CountPlanList.vue"),
  },
  // 棚卸実績登録
  {
    path: "/" + appConfig.SCREEN_ID.P_CNT_004, //"/cnt_achieve_list",
    name: appConfig.SCREEN_ID.P_CNT_004, //"CountAchieveList",
    meta: {
      screenId: appConfig.SCREEN_ID.P_CNT_004,
    },
    component: () =>
      import(/* webpackChunkName: "cnt_achieve_list" */ "../views/count/CountAchieveAdd.vue"),
  },
  // 棚卸実績承認
  {
    path: "/" + appConfig.SCREEN_ID.P_CNT_006, //"/cnt_achieve_list",
    name: appConfig.SCREEN_ID.P_CNT_006, //"CountAchieveList",
    meta: {
      screenId: appConfig.SCREEN_ID.P_CNT_006,
    },
    component: () =>
      import(/* webpackChunkName: "cnt_achieve_list" */ "../views/count/CountAchieveApproval.vue"),
  },
  // １０－１．請求契約一覧
  {
    path: "/" + appConfig.SCREEN_ID.P_BIL_001,
    name: appConfig.SCREEN_ID.P_BIL_001, //"CntPlanList",
    meta: {
      screenId: appConfig.SCREEN_ID.P_BIL_001,
    },
    component: () =>
      import(
        /* webpackChunkName: "bil_contract_list" */ "../views/billing/BillingContractList.vue"
      ),
  },
  // １０－２．請求月締め処理
  {
    path: "/" + appConfig.SCREEN_ID.P_BIL_003,
    name: appConfig.SCREEN_ID.P_BIL_003, //"bil_cutOffMonthRegistration",
    meta: {
      screenId: appConfig.SCREEN_ID.P_BIL_003,
    },
    component: () =>
      import(
        /* webpackChunkName: "bil_cutOffMonthRegistration" */ "../views/billing/BillingCutOffDateRegistration.vue"
      ),
  },
  // １０－３．請求実績一覧
  {
    path: "/" + appConfig.SCREEN_ID.P_BIL_002,
    name: appConfig.SCREEN_ID.P_BIL_002, //"bil_actual_list",
    meta: {
      screenId: appConfig.SCREEN_ID.P_BIL_002,
    },
    component: () =>
      import(/* webpackChunkName: "bil_actual_list" */ "../views/billing/BillingActualList.vue"),
  },
  // １０－４．請求契約単価登録変更
  {
    path: "/" + appConfig.SCREEN_ID.P_BIL_004,
    name: appConfig.SCREEN_ID.P_BIL_004, //"bil_contract_unit_price_registration",
    meta: {
      screenId: appConfig.SCREEN_ID.P_BIL_004,
    },
    component: () =>
      import(
        /* webpackChunkName: "bil_contract_unit_price_registration" */ "../views/billing/BillingContractUnitPriceRegistration.vue"
      ),
  },
  // 請求実績登録変更
  {
    path: "/" + appConfig.SCREEN_ID.P_BIL_005,
    name: appConfig.SCREEN_ID.P_BIL_005, //"bil_actual_add_upd",
    meta: {
      screenId: appConfig.SCREEN_ID.P_BIL_005,
    },
    component: () =>
      import(
        /* webpackChunkName: "bil_actual_add_upd" */ "../views/billing/BillingActualAddUpd.vue"
      ),
  },
  // １０－４．請求内訳書発行
  {
    path: "/" + appConfig.SCREEN_ID.P_BIL_006,
    name: appConfig.SCREEN_ID.P_BIL_006, //"bil_cutOffMonthRegistration",
    meta: {
      screenId: appConfig.SCREEN_ID.P_BIL_006,
    },
    component: () =>
      import(
        /* webpackChunkName: "bil_cutOffMonthRegistration" */ "../views/billing/BillingStatementIssucance.vue"
      ),
  },
  // １１．返品実績承認
  {
    path: "/" + appConfig.SCREEN_ID.P_RTN_003,
    name: appConfig.SCREEN_ID.P_RTN_003, //"bil_cutOffMonthRegistration",
    meta: {
      screenId: appConfig.SCREEN_ID.P_RTN_003,
    },
    component: () =>
      import(
        /* webpackChunkName: "bil_cutOffMonthRegistration" */ "../views/return/ReturnSlipApproval.vue"
      ),
  },
  // １２－１．予定実績対比
  {
    path: "/" + appConfig.SCREEN_ID.P_MNG_001, //"/mng_schedule_achievements",
    name: appConfig.SCREEN_ID.P_MNG_001, //"ManageCompareScheduleAchievements",
    meta: {
      screenId: appConfig.SCREEN_ID.P_MNG_001,
    },
    component: () =>
      import(
        /* webpackChunkName: "compare_schedule_achievements" */ "../views/mng/ManageCompareScheduleAchievements.vue"
      ),
  },
  // 入荷履歴
  {
    path: "/" + appConfig.SCREEN_ID.P_MNG_002, //"/rcv_history",
    name: appConfig.SCREEN_ID.P_MNG_002, //"ReceivingHistory",
    meta: {
      screenId: appConfig.SCREEN_ID.P_MNG_002,
    },
    component: () =>
      import(
        /* webpackChunkName: "rcv_achieve_update" */ "../views/receiving/ReceivingHistory.vue"
      ),
  },
  // 入庫履歴
  {
    path: "/" + appConfig.SCREEN_ID.P_MNG_003, //"/ent_history",
    name: appConfig.SCREEN_ID.P_MNG_003, //"EnteringHistory",
    meta: {
      screenId: appConfig.SCREEN_ID.P_MNG_003,
    },
    component: () =>
      import(
        /* webpackChunkName: "ext_achieve_updateDelete" */ "../views/entering/EnteringHistory.vue"
      ),
  },
  // 出庫履歴
  {
    path: "/" + appConfig.SCREEN_ID.P_MNG_004, //"/ext_history",
    name: appConfig.SCREEN_ID.P_MNG_004,
    meta: {
      screenId: appConfig.SCREEN_ID.P_MNG_004,
    },
    component: () =>
      import(/* webpackChunkName: "ext_history" */ "../views/exiting/ExitingHistory.vue"),
  },
  // 出荷履歴
  {
    path: "/" + appConfig.SCREEN_ID.P_MNG_005, //"/ship_history",
    name: appConfig.SCREEN_ID.P_MNG_005, //"ShipHistory",
    meta: {
      screenId: appConfig.SCREEN_ID.P_MNG_005,
    },
    component: () => import(/* webpackChunkName: "ship_history" */ "../views/ship/ShipHistory.vue"),
  },
  // １２－２．入出荷履歴
  {
    path: "/" + appConfig.SCREEN_ID.P_MNG_006, //"/mng_schedule_achievements",
    name: appConfig.SCREEN_ID.P_MNG_006, //"ManageCompareScheduleAchievements",
    meta: {
      screenId: appConfig.SCREEN_ID.P_MNG_006,
    },
    component: () =>
      import(
        /* webpackChunkName: "compare_schedule_achievements" */ "../views/mng/ManageEnteringExtensioHistory.vue"
      ),
  },
  // １２－３．工程実績一覧修正
  {
    path: "/" + appConfig.SCREEN_ID.P_MNG_008,
    name: appConfig.SCREEN_ID.P_MNG_008,
    meta: {
      screenId: appConfig.SCREEN_ID.P_MNG_008,
    },
    component: () =>
      import(
        /* webpackChunkName: "compare_schedule_achievements" */ "../views/mng/ManageOpeHist.vue"
      ),
  },
  // １２－４．トレース対象検索
  {
    path: "/" + appConfig.SCREEN_ID.P_MNG_010,
    name: appConfig.SCREEN_ID.P_MNG_010,
    meta: {
      screenId: appConfig.SCREEN_ID.P_MNG_010,
    },
    component: () =>
      import(
        /* webpackChunkName: "compare_schedule_achievements" */ "../views/mng/TraceSearch.vue"
      ),
  },
  // １２－９．ロケーションバーコード
  {
    path: "/" + appConfig.SCREEN_ID.P_MNG_099,
    name: appConfig.SCREEN_ID.P_MNG_099,
    meta: {
      screenId: appConfig.SCREEN_ID.P_MNG_099,
    },
    component: () =>
      import(
        /* webpackChunkName: "compare_schedule_achievements" */ "../views/mng/CreateLocationBarcode.vue"
      ),
  },
  // １２－４．トレース展開
  {
    path: "/" + appConfig.SCREEN_ID.P_MNG_011,
    name: appConfig.SCREEN_ID.P_MNG_011,
    meta: {
      screenId: appConfig.SCREEN_ID.P_MNG_011,
    },
    component: () =>
      import(
        /* webpackChunkName: "compare_schedule_achievements" */ "../views/mng/TraceDeploy.vue"
      ),
  },

  // 倉庫マスタ登録
  {
    path: "/" + appConfig.SCREEN_ID.P_MST_001, //"/com_MstWarehouse_Add",
    name: appConfig.SCREEN_ID.P_MST_001, //"MstWarehouseAdd",
    meta: {
      screenId: appConfig.SCREEN_ID.P_MST_001,
    },
    component: () =>
      import(/* webpackChunkName: "mst_warehouse_add" */ "../views/common/MstWarehouseAdd.vue"),
  },
  // １３－１．倉庫マスタ一覧
  {
    path: "/" + appConfig.SCREEN_ID.P_MST_002, //"/com_MstWarehouse_List",
    name: appConfig.SCREEN_ID.P_MST_002, //"MstWarehouseList",
    meta: {
      screenId: appConfig.SCREEN_ID.P_MST_002,
    },
    component: () =>
      import(/* webpackChunkName: "mst_warehouse_list" */ "../views/common/MstWarehouseList.vue"),
  },
  // １３－２．ロケーションマスタ一覧
  {
    path: "/" + appConfig.SCREEN_ID.P_MST_005, //"/com_MstLocation_List",
    name: appConfig.SCREEN_ID.P_MST_005, //"MstLocationList",
    meta: {
      screenId: appConfig.SCREEN_ID.P_MST_005,
    },
    component: () =>
      import(/* webpackChunkName: "mst_location_list" */ "../views/common/MstLocationList.vue"),
  },
  // ロケーションマスタ登録、修正
  {
    path: "/" + appConfig.SCREEN_ID.P_MST_003, //"/com_MstLocation_Add",
    name: appConfig.SCREEN_ID.P_MST_003, //"MstLocationAdd",
    meta: {
      screenId: appConfig.SCREEN_ID.P_MST_003,
    },
    component: () =>
      import(/* webpackChunkName: "mst_location_add" */ "../views/common/MstLocationAdd.vue"),
  },
  // １３－３．商品マスタ一覧
  {
    path: "/" + appConfig.SCREEN_ID.P_MST_007, //"/com_item_master_List",
    name: appConfig.SCREEN_ID.P_MST_007, //"MstItemsList",
    meta: {
      screenId: appConfig.SCREEN_ID.P_MST_007,
    },
    component: () =>
      import(/* webpackChunkName: "mst_item_master_List" */ "../views/common/MstItemsList.vue"),
  },
  // ９０－１０．バーコードマスタ
  {
    path: "/" + appConfig.SCREEN_ID.P_INI_004,
    name: appConfig.SCREEN_ID.P_INI_004,
    meta: {
      screenId: appConfig.SCREEN_ID.P_INI_004,
    },
    component: () =>
      import(/* webpackChunkName: "barcode_master" */ "../views/mng/BarcodeMaster.vue"),
  },
  // バーコード情報登録
  {
    path: "/" + appConfig.SCREEN_ID.P_INI_005,
    name: appConfig.SCREEN_ID.P_INI_005,
    meta: {
      screenId: appConfig.SCREEN_ID.P_INI_005,
    },
    component: () =>
      import(/* webpackChunkName: "barcode_info_add" */ "../views/mng/BarcodeInfoAdd.vue"),
  },
  // ラベル印刷制御登録
  {
    path: "/" + appConfig.SCREEN_ID.P_INI_006,
    name: appConfig.SCREEN_ID.P_INI_006,
    meta: {
      screenId: appConfig.SCREEN_ID.P_INI_006,
    },
    component: () =>
      import(/* webpackChunkName: "label_print_ctrl_add" */ "../views/mng/LabelPrintCtrlAdd.vue"),
  },
  // ハンディー画面制御登録
  {
    path: "/" + appConfig.SCREEN_ID.P_INI_007,
    name: appConfig.SCREEN_ID.P_INI_007,
    meta: {
      screenId: appConfig.SCREEN_ID.P_INI_007,
    },
    component: () =>
      import(/* webpackChunkName: "ht_disp_ctrl_add" */ "../views/mng/HtDispCtrlAdd.vue"),
  },
  // ９０－１１．DP流通加工マスタ登録状況一覧
  {
    path: "/" + appConfig.SCREEN_ID.P_INI_008,
    name: appConfig.SCREEN_ID.P_INI_008,
    meta: {
      screenId: appConfig.SCREEN_ID.P_INI_008,
    },
    component: () =>
      import(
        /* webpackChunkName: "distribution_processing_masterRegist_list" */ "../views/mng/DistributionProcessingMasterRegistList.vue"
      ),
  },
  // ９０－１２．作業手順メンテナンス
  {
    path: "/" + appConfig.SCREEN_ID.P_INI_009,
    name: appConfig.SCREEN_ID.P_INI_009,
    meta: {
      screenId: appConfig.SCREEN_ID.P_INI_009,
    },
    component: () =>
      import(
        /* webpackChunkName: "work_procedure_maintenance" */ "../views/mng/WorkProcedureMaintenance.vue"
      ),
  },
  // 検査項目メンテナンス
  {
    path: "/" + appConfig.SCREEN_ID.P_INI_010,
    name: appConfig.SCREEN_ID.P_INI_010,
    meta: {
      screenId: appConfig.SCREEN_ID.P_INI_010,
    },
    component: () =>
      import(
        /* webpackChunkName: "test_item_maintenance" */ "../views/mng/TestItemMaintenance.vue"
      ),
  },
  //０５－１．流通加工計画登録画面
  {
    path: "/" + appConfig.SCREEN_ID.P_TMP_901,
    name: appConfig.SCREEN_ID.P_TMP_901,
    meta: {
      screenId: appConfig.SCREEN_ID.P_TMP_901,
    },
    component: () =>
      import(
        /* webpackChunkName: "shp_data_import" */ "../views/distribution/DistributionProcessingPlanAdd.vue"
      ),
  },
  //０５－２．流通加工計画データ取込
  {
    path: "/" + appConfig.SCREEN_ID.P_TMP_902,
    name: appConfig.SCREEN_ID.P_TMP_902,
    meta: {
      screenId: appConfig.SCREEN_ID.P_TMP_902,
    },
    component: () =>
      import(
        /* webpackChunkName: "shp_data_import" */ "../views/distribution/DistributionDataImport.vue"
      ),
  },
  //０５－３．流通加工計画検索一覧画面
  {
    path: "/" + appConfig.SCREEN_ID.P_TMP_903,
    name: appConfig.SCREEN_ID.P_TMP_903,
    meta: {
      screenId: appConfig.SCREEN_ID.P_TMP_903,
    },
    component: () =>
      import(
        /* webpackChunkName: "shp_data_import" */ "../views/distribution/DistributionScheduleList.vue"
      ),
  },
  //０５－４．加工計画修正・削除画面
  {
    path: "/" + appConfig.SCREEN_ID.P_TMP_904,
    name: appConfig.SCREEN_ID.P_TMP_904,
    meta: {
      screenId: appConfig.SCREEN_ID.P_TMP_904,
    },
    component: () =>
      import(
        /* webpackChunkName: "shp_data_import" */ "../views/distribution/DistribusionScheduleUpdateDelete.vue"
      ),
  },
  //加工完成指示作成画面
  {
    path: "/" + appConfig.SCREEN_ID.P_TMP_906,
    name: appConfig.SCREEN_ID.P_TMP_906,
    meta: {
      screenId: appConfig.SCREEN_ID.P_TMP_906,
    },
    component: () =>
      import(
        /* webpackChunkName: "shp_data_import" */ "../views/distribution/DistributionInstructionsCreation.vue"
      ),
  },
  //加工引当結果確認
  {
    path: "/" + appConfig.SCREEN_ID.P_TMP_907,
    name: appConfig.SCREEN_ID.P_TMP_907,
    meta: {
      screenId: appConfig.SCREEN_ID.P_TMP_907,
    },
    component: () =>
      import(
        /* webpackChunkName: "shp_data_import" */ "../views/distribution/DistributionAllocationResult.vue"
      ),
  },
  //加工実績登録
  {
    path: "/" + appConfig.SCREEN_ID.P_TMP_908,
    name: appConfig.SCREEN_ID.P_TMP_908,
    meta: {
      screenId: appConfig.SCREEN_ID.P_TMP_908,
    },
    component: () =>
      import(
        /* webpackChunkName: "shp_data_import" */ "../views/distribution/DistributionAchieveAdd.vue"
      ),
  },
  //加工完成指示取消
  {
    path: "/" + appConfig.SCREEN_ID.P_TMP_909,
    name: appConfig.SCREEN_ID.P_TMP_909,
    meta: {
      screenId: appConfig.SCREEN_ID.P_TMP_909,
    },
    component: () =>
      import(
        /* webpackChunkName: "shp_data_import" */ "../views/distribution/DistributionInstructionsCancel.vue"
      ),
  },
  // 加工計画進捗
  {
    path: "/" + appConfig.SCREEN_ID.P_TMP_910,
    name: appConfig.SCREEN_ID.P_TMP_910,
    meta: {
      screenId: appConfig.SCREEN_ID.P_TMP_910,
    },
    component: () =>
      import(
        /* webpackChunkName: "shp_data_import" */ "../views/distribution/DistributionCompareScheduleAchievements.vue"
      ),
  },
  // 加工計画進捗詳細
  {
    path: "/" + appConfig.SCREEN_ID.P_TMP_911,
    name: appConfig.SCREEN_ID.P_TMP_911,
    meta: {
      screenId: appConfig.SCREEN_ID.P_TMP_911,
    },
    component: () =>
      import(
        /* webpackChunkName: "shp_data_import" */ "../views/distribution/DistribusionHistory.vue"
      ),
  },
  // 資材発注点登録
  {
    path: "/" + appConfig.SCREEN_ID.P_TMP_912,
    name: appConfig.SCREEN_ID.P_TMP_912,
    meta: {
      screenId: appConfig.SCREEN_ID.P_TMP_912,
    },
    component: () =>
      import(
        /* webpackChunkName: "shp_data_import" */ "../views/distribution/DistributionMaterialsOrderingPointAdd.vue"
      ),
  },
  // 工程別在庫確認
  {
    path: "/" + appConfig.SCREEN_ID.P_TMP_913,
    name: appConfig.SCREEN_ID.P_TMP_913,
    meta: {
      screenId: appConfig.SCREEN_ID.P_TMP_913,
    },
    component: () =>
      import(
        /* webpackChunkName: "shp_data_import" */ "../views/distribution/DistributionInventoryConfirmationByProcess.vue"
      ),
  },
  // 商品マスタ修正削除
  {
    path: "/" + appConfig.SCREEN_ID.P_MST_008, //"/com_item_master_UpdateDelete",
    name: appConfig.SCREEN_ID.P_MST_008, //"MstItemsUpdateDelete",
    meta: {
      screenId: appConfig.SCREEN_ID.P_MST_008,
    },
    component: () =>
      import(
        /* webpackChunkName: "mst_item_master_UpdateDElete" */ "../views/common/MstItemsUpdateDelete.vue"
      ),
  },

  {
    meta: {
      screenId: appConfig.SCREEN_ID.P_COM_000,
    },
    path: "/Location",
    name: "Location",
    component: () => import(/* webpackChunkName: "Location" */ "../views/common/Location.vue"),
  },

  {
    meta: {
      screenId: appConfig.SCREEN_ID.P_COM_000,
    },
    path: "/ent_achieve_updateDelete",
    name: "EntAchieveUpdateDelete",
    component: () =>
      import(
        /* webpackChunkName: "ent_achieve_updateDelete" */ "../views/entering/EnteringAchieveUpdateDelete.vue"
      ),
  },

  {
    path: "/rcv_achieve_update",
    name: "RcvAchieveUpdate",
    meta: {
      screenId: appConfig.SCREEN_ID.P_MNG_000,
    },
    component: () =>
      import(
        /* webpackChunkName: "rcv_achieve_update" */ "../views/receiving/ReceivingAchieveUpdate.vue"
      ),
  },

  {
    path: "/shp_achieve_updateDelete",
    name: "ShpAchieveUpdateDelete",
    meta: {
      screenId: appConfig.SCREEN_ID.P_SHP_007,
    },
    component: () =>
      import(
        /* webpackChunkName: "shp_achieve_updateDelete" */ "../views/ship/ShipAchieveUpdateDelete.vue"
      ),
  },
  {
    path: "/" + appConfig.SCREEN_ID.P_SHP_010,
    name: appConfig.SCREEN_ID.P_SHP_010,
    meta: {
      screenId: appConfig.SCREEN_ID.P_SHP_010,
    },
    component: () =>
      import(
        /* webpackChunkName: "shp_additional_info_by_format" */ "../views/route/AdditionalInformationByFormat.vue"
      ),
  },

  {
    path: "/ext_achieve_updateDelete",
    name: "ExtAchieveUpdateDelete",
    meta: {
      screenId: appConfig.SCREEN_ID.P_CNT_003,
    },
    component: () =>
      import(
        /* webpackChunkName: "ext_achieve_updateDelete" */ "../views/exiting/ExitingAchieveUpdateDelete.vue"
      ),
  },

  {
    path: "/" + appConfig.MENU_ID.P_RCV_ACTUAL_ADD,
    name: appConfig.MENU_ID.P_RCV_ACTUAL_ADD,
    meta: {
      screenId: appConfig.SCREEN_ID.P_RCV_006,
    },
    component: () =>
      import(
        /* webpackChunkName: "receiving_achieve_add" */ "../views/receiving/ReceivingAchieveAdd.vue"
      ),
  },
  {
    path: "/" + appConfig.MENU_ID.P_RTN_SLIP_LIST,
    name: appConfig.MENU_ID.P_RTN_SLIP_LIST,
    meta: {
      screenId: appConfig.SCREEN_ID.P_RTN_002,
    },
    component: () =>
      import(
        /* webpackChunkName: "return_schedule_list" */ "../views/return/ReturnScheduleList.vue"
      ),
  },
  {
    path: "/" + appConfig.MENU_ID.P_RTN_SLIP_ADD,
    name: appConfig.MENU_ID.P_RTN_SLIP_ADD,
    meta: {
      screenId: appConfig.SCREEN_ID.P_CNT_003,
    },
    component: () =>
      import(/* webpackChunkName: "return_slip_add" */ "../views/return/ReturnSlipAdd.vue"),
  },
  {
    path: "/" + appConfig.MENU_ID.P_ENT_ACTUAL_ADD,
    name: appConfig.MENU_ID.P_ENT_ACTUAL_ADD,
    meta: {
      screenId: appConfig.SCREEN_ID.P_ENT_002,
    },
    component: () =>
      import(
        /* webpackChunkName: "entering_achieve_add" */ "../views/entering/EnteringAchieveAdd.vue"
      ),
  },
  {
    path: "*",
    name: "not_found",
    meta: {
      isPublic: true,
      screenId: appConfig.SCREEN_ID.P_COM_001,
    },
    component: () => import("../views/login.vue"),
  },
  {
    meta: {
      screenId: appConfig.SCREEN_ID.P_COM_000,
    },
    path: "/list1",
    name: "list1",
    component: () => import(/* webpackChunkName: "list1" */ "../views/pattern/List1.vue"),
  },
  {
    meta: {
      screenId: appConfig.SCREEN_ID.P_COM_000,
    },
    path: "/listAdd1",
    name: "listAdd1",
    component: () => import(/* webpackChunkName: "listAdd1" */ "../views/pattern/ListAdd1.vue"),
  },
  {
    meta: {
      screenId: appConfig.SCREEN_ID.P_COM_000,
    },
    path: "/listAdd2",
    name: "listAdd2",
    component: () => import(/* webpackChunkName: "listAdd2" */ "../views/pattern/ListAdd2.vue"),
  },
  {
    meta: {
      screenId: appConfig.SCREEN_ID.P_COM_000,
    },
    path: "/listAdd3",
    name: "listAdd3",
    component: () => import(/* webpackChunkName: "listAdd3" */ "../views/pattern/ListAdd3.vue"),
  },
  {
    meta: {
      screenId: appConfig.SCREEN_ID.P_COM_000,
    },
    path: "/import",
    name: "import",
    component: () => import(/* webpackChunkName: "import" */ "../views/pattern/Import.vue"),
  },
  {
    meta: {
      screenId: appConfig.SCREEN_ID.P_COM_000,
    },
    path: "/register2",
    name: "register2",
    component: () => import(/* webpackChunkName: "register2" */ "../views/pattern/Register2.vue"),
  },
  {
    meta: {
      screenId: appConfig.SCREEN_ID.P_COM_000,
    },
    path: "/listPopup",
    name: "listPopup",
    component: () => import(/* webpackChunkName: "listPopup" */ "../views/pattern/ListPopup.vue"),
  },
  // １３－４．ユーザーマスタ一覧
  {
    path: "/" + appConfig.SCREEN_ID.P_MST_012, //"/com_MstWarehouse_List",
    name: appConfig.SCREEN_ID.P_MST_012, //"MstWarehouseList",
    meta: {
      screenId: appConfig.SCREEN_ID.P_MST_012,
    },
    component: () => import(/* webpackChunkName: "users" */ "../views/mng/ManageUsers.vue"),
  },
  // １３－４．ユーザーマスタ編集
  {
    path: "/" + appConfig.SCREEN_ID.P_MST_013, //"/com_MstWarehouse_List",
    name: appConfig.SCREEN_ID.P_MST_013, //"MstWarehouseList",
    meta: {
      screenId: appConfig.SCREEN_ID.P_MST_013,
    },
    component: () => import(/* webpackChunkName: "users" */ "../views/mng/ManageUserInfo.vue"),
  },
  // １３－５．マスタ一括取込
  {
    path: "/" + appConfig.SCREEN_ID.P_MST_014, //"/com_MstWarehouse_List",
    name: appConfig.SCREEN_ID.P_MST_014, //"MstWarehouseList",
    meta: {
      screenId: appConfig.SCREEN_ID.P_MST_014,
    },
    component: () => import(/* webpackChunkName: "users" */ "../views/mng/ManageDataImport.vue"),
  },
  // マスタ一括取込エラー確認
  {
    meta: {
      screenId: appConfig.SCREEN_ID.P_MST_015,
    },
    path: "/" + appConfig.SCREEN_ID.P_MST_015, //"/mg_data_import_error",
    name: appConfig.SCREEN_ID.P_MST_015, //"MgDataImportError",
    component: () =>
      import(
        /* webpackChunkName: "mg_data_import_error" */ "../views/mng/ManageDataImportError.vue"
      ),
  },
  //  画面権限一括修正
  {
    path: "/" + appConfig.SCREEN_ID.P_MST_016, //"/com_MstWarehouse_List",
    name: appConfig.SCREEN_ID.P_MST_016, //"MstWarehouseList",
    meta: {
      screenId: appConfig.SCREEN_ID.P_MST_016,
    },
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/common/MstRoleBatchAddUpdate.vue"),
  },
  //関係先マスタ 登録
  {
    path: "/" + appConfig.SCREEN_ID.P_MST_017, //"/com_MstRelatedParties_add",
    name: appConfig.SCREEN_ID.P_MST_017, //"MstRelatedPartiesAdd",
    meta: {
      screenId: appConfig.SCREEN_ID.P_MST_017,
    },
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/common/MstRelatedPartiesAdd.vue"),
  },
  //関係先マスタ 一覧
  {
    path: "/" + appConfig.SCREEN_ID.P_MST_018, //"/com_MstRelatedParties_list",
    name: appConfig.SCREEN_ID.P_MST_018, //"MstRelatedPartiesList",
    meta: {
      screenId: appConfig.SCREEN_ID.P_MST_018,
    },
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/common/MstRelatedPartiesList.vue"),
  },
  // １４－１．全社進捗編集
  {
    path: "/" + appConfig.SCREEN_ID.P_TRS_001, //"/com_MstWarehouse_List",
    name: appConfig.SCREEN_ID.P_TRS_001, //"MstWarehouseList",
    meta: {
      screenId: appConfig.SCREEN_ID.P_TRS_001,
    },
    component: () => import(/* webpackChunkName: "users" */ "../views/transition/Transition.vue"),
  },
  // １5－１ ユーザー品番マスタ一覧
  {
    path: "/" + appConfig.SCREEN_ID.P_SUP_012, //"/com_MstWarehouse_List",
    name: appConfig.SCREEN_ID.P_SUP_012, //"MstWarehouseList",
    meta: {
      screenId: appConfig.SCREEN_ID.P_SUP_012,
    },
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/procurement/MstUserProductList.vue"),
  },
  // ユーザー品番マスタ修正・削除
  {
    path: "/" + appConfig.SCREEN_ID.P_SUP_013, //"/com_MstWarehouse_List",
    name: appConfig.SCREEN_ID.P_SUP_013, //"MstWarehouseList",
    meta: {
      screenId: appConfig.SCREEN_ID.P_SUP_013,
    },
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/procurement/MstUserProductAdd.vue"),
  },
  // １5－２ ユーザメーカーリンクマスタ一覧
  {
    path: "/" + appConfig.SCREEN_ID.P_SUP_015, //"/com_MstWarehouse_List",
    name: appConfig.SCREEN_ID.P_SUP_015, //"MstWarehouseList",
    meta: {
      screenId: appConfig.SCREEN_ID.P_SUP_015,
    },
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/procurement/MstUserMakerLinkList.vue"),
  },
  // ユーザメーカーリンクマスタ登録
  {
    path: "/" + appConfig.SCREEN_ID.P_SUP_016, //"/com_MstWarehouse_List",
    name: appConfig.SCREEN_ID.P_SUP_016, //"MstWarehouseList",
    meta: {
      screenId: appConfig.SCREEN_ID.P_SUP_016,
    },
    component: () =>
      import(
        /* webpackChunkName: "users" */ "../views/procurement/MstUserMakerLinkUpdateDelete.vue"
      ),
  },
  // 納入指示計画一覧
  {
    path: "/" + appConfig.SCREEN_ID.P_SUP_001, //"/com_MstWarehouse_List",
    name: appConfig.SCREEN_ID.P_SUP_001, //"MstWarehouseList",
    meta: {
      screenId: appConfig.SCREEN_ID.P_SUP_001,
    },
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/procurement/deliveryOrderPlanList.vue"),
  },
  // 納入指示計画登録
  {
    path: "/" + appConfig.SCREEN_ID.P_SUP_002, //"/deliveryOrderPlanAdd",
    name: appConfig.SCREEN_ID.P_SUP_002, //"deliveryOrderPlanAdd",
    meta: {
      screenId: appConfig.SCREEN_ID.P_SUP_002,
    },
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/procurement/deliveryOrderPlanAdd.vue"),
  },
  // 納入指示計画修正
  {
    path: "/" + appConfig.SCREEN_ID.P_SUP_003, //"/deliveryOrderPlanUpdate.vue",
    name: appConfig.SCREEN_ID.P_SUP_003, //"deliveryOrderPlanUpdate.vue",
    meta: {
      screenId: appConfig.SCREEN_ID.P_SUP_003,
    },
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/procurement/deliveryOrderPlanUpdate.vue"),
  },
  // User_Makerリンクマスタ
  {
    path: "/" + appConfig.SCREEN_ID.P_SUP_017, //"/com_MstWarehouse_List",
    name: appConfig.SCREEN_ID.P_SUP_017, //"MstWarehouseList",
    meta: {
      screenId: appConfig.SCREEN_ID.P_SUP_017,
    },
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/procurement/MstUserMakerLink.vue"),
  },
  // 搬入計画仕分マスタ
  {
    path: "/" + appConfig.SCREEN_ID.P_SUP_018, //"/com_MstDeliveryOrderSorting_List",
    name: appConfig.SCREEN_ID.P_SUP_018, //"MstDeliveryOrderSorting",
    meta: {
      screenId: appConfig.SCREEN_ID.P_SUP_018,
    },
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/procurement/MstDeliveryOrderSorting.vue"),
  },
  // ユーザ品番マスタ
  {
    path: "/" + appConfig.SCREEN_ID.P_SUP_019, //"/com_MstUserItem",
    name: appConfig.SCREEN_ID.P_SUP_019, //"MstUserItem",
    meta: {
      screenId: appConfig.SCREEN_ID.P_SUP_019,
    },
    component: () => import(/* webpackChunkName: "users" */ "../views/procurement/MstUserItem.vue"),
  },
  // 在庫推移(ユーザー品番)
  {
    path: "/" + appConfig.SCREEN_ID.P_SUP_020, //"/com_MstUserItem",
    name: appConfig.SCREEN_ID.P_SUP_020, //"MstUserItem",
    meta: {
      screenId: appConfig.SCREEN_ID.P_SUP_020,
    },
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/procurement/UserMakerStockTransition.vue"),
  },
  // 在庫推移(調達)
  {
    path: "/" + appConfig.SCREEN_ID.P_SUP_021, //"/com_PocurementStockTransitionp",
    name: appConfig.SCREEN_ID.P_SUP_021, //"PocurementStockTransitionp",
    meta: {
      screenId: appConfig.SCREEN_ID.P_SUP_021,
    },
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/procurement/ProcurementStockTransition.vue"),
  },
  // 異常通知
  {
    path: "/" + appConfig.SCREEN_ID.P_SUP_004, //"/com_MstWarehouse_List",
    name: appConfig.SCREEN_ID.P_SUP_004, //"MstWarehouseList",
    meta: {
      screenId: appConfig.SCREEN_ID.P_SUP_004,
    },
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/procurement/deliveryOrderPlanNotice.vue"),
  },
  // 納入指示計画一覧
  {
    path: "/" + appConfig.SCREEN_ID.P_SUP_005,
    name: appConfig.SCREEN_ID.P_SUP_005,
    meta: {
      screenId: appConfig.SCREEN_ID.P_SUP_005,
    },
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/procurement/DeliveryOrderDataImport.vue"),
  },
  // 受注登録画面
  {
    path: "/" + appConfig.SCREEN_ID.P_TOD_001,
    name: appConfig.SCREEN_ID.P_TOD_001,
    meta: {
      // isPublic: true,
      screenId: appConfig.SCREEN_ID.P_TOD_001,
    },
    component: () => import(/* webpackChunkName: "users" */ "../views/transport/transportAdd.vue"),
  },
  // 受注一覧画面
  {
    path: "/" + appConfig.SCREEN_ID.P_TOD_002,
    name: appConfig.SCREEN_ID.P_TOD_002,
    meta: {
      // isPublic: true,
      screenId: appConfig.SCREEN_ID.P_TOD_002,
    },
    component: () => import(/* webpackChunkName: "users" */ "../views/transport/transportList.vue"),
  },
  // 受注修正削除画面
  {
    path: "/" + appConfig.SCREEN_ID.P_TOD_003,
    name: appConfig.SCREEN_ID.P_TOD_003,
    meta: {
      // isPublic: true,
      screenId: appConfig.SCREEN_ID.P_TOD_003,
    },
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/transport/transportUpdateDelete.vue"),
  },
  // 配車一覧画面
  {
    path: "/" + appConfig.SCREEN_ID.P_DVP_001, //"/com_Dispatch_list",
    name: appConfig.SCREEN_ID.P_DVP_001, //"DispatchList",
    meta: {
      screenId: appConfig.SCREEN_ID.P_DVP_001,
    },
    component: () => import(/* webpackChunkName: "users" */ "../views/dispatch/DispatchList.vue"),
  },
  // 配車登録画面
  {
    path: "/" + appConfig.SCREEN_ID.P_DVP_002,
    name: appConfig.SCREEN_ID.P_DVP_002,
    meta: {
      screenId: appConfig.SCREEN_ID.P_DVP_002,
    },
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/dispatch/VehicleDispatchRegister.vue"),
  },
  // 外部用問合せ画面
  {
    path: "/" + appConfig.SCREEN_ID.P_TRN_001,
    name: appConfig.SCREEN_ID.P_TRN_001,
    meta: {
      isPublic: true,
      screenId: appConfig.SCREEN_ID.P_TRN_001,
    },
    component: () => import(/* webpackChunkName: "users" */ "../views/inquiry/InquiryList.vue"),
  },
  // 問合せ画面
  {
    path: "/" + appConfig.SCREEN_ID.P_TRN_002,
    name: appConfig.SCREEN_ID.P_TRN_002,
    meta: {
      screenId: appConfig.SCREEN_ID.P_TRN_002,
    },
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/inquiry/InquiryListInside.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  //const roles = sessionStorage.getItem("role");
  let canScreen = sessionStorage.getItem(to.meta.screenId) == "true" ? true : false;
  //let canScreen = true;
  //if (roles) {
  //const role = roles.split(",")[0];
  //canScreen = screenAuth.canScreenAuth(role, to.meta.screenId);
  //}
  // FIXME テストコード Cognitoが正式版になったら削除してください。
  // metaのisPublic設定がtrueでないページについては、idトークンと使用権限が必須
  if (
    to.matched.some((page) => page.meta.isPublic) ||
    (sessionStorage.getItem("idToken") !== null && canScreen)
  ) {
    if (
      to.meta.screenId == appConfig.SCREEN_ID.P_INI_005 ||
      to.meta.screenId == appConfig.SCREEN_ID.P_INI_006 ||
      to.meta.screenId == appConfig.SCREEN_ID.P_INI_007
    ) {
      const getOfficeModuleInfo = getParameter.getOfficeModule({
        officeCd: sessionStorage.getItem("office_cd"),
      });
      Promise.all([getOfficeModuleInfo]).then((result) => {
        const completeFlgCnt = result[0].filter((item) => item.completeFlg === "1").length;
        if (completeFlgCnt > 0) {
          next();
        } else {
          alert("マスタ初期導入を完了してください。");
          next("/");
        }
      });
    } else {
      next();
    }
  } else {
    if (!canScreen) {
      alert("選択した画面を参照する権限がありません。");
      next("/");
    } else {
      alert("セッションタイムアウトしています。再度ログインしてください。");
      next("/");
    }
  }
});

export default router;
