import { appConfig } from "./AppConfig";
import { messsageUtil } from "./MesssageUtil";
import { httpClient } from "./HttpClient";
import { commonUtil } from "./CommonUtil";

export default class GetParameter {
  /**
   * 営業所取得
   */
  getOffice(officeSid = sessionStorage.getItem("sales_office_sid")) {
    const config = httpClient.createGetApiRequestConfig();
    config.params.isMst = "1";
    config.params.relatedPartyDiv = "02";
    config.params.officeSid = officeSid;
    // console.debug("GetParameter getOffice Url", config);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.MST_RELATED, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          //console.debug("GetParameter getOffice Response", jsonData);
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            const list = [];
            jsonData.resIdv.relatedPartys.forEach((row) => {
              if (row.relatedPartyLanguage.length > 0) {
                list.push({
                  text: `${row.officeCd}` + `（${row.relatedPartyLanguage[0].relatedPartyName}）`,
                  value: row.relatedPartySid,
                  name: row.relatedPartyLanguage[0].relatedPartyName,
                  code: row.officeCd,
                });
              }
            });
            resolve(list);
            // エラー時
          } else {
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameter getOffice Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * 業務利用関係先マスタ取得
   */
  getRelatedBiz(
    isClient,
    utcDate,
    isAvailableBillingClosing,
    officeCd,
    officeSid = sessionStorage.getItem("sales_office_sid")
  ) {
    const config = httpClient.createGetApiRequestConfig();
    if (isClient == "0") {
      config.params.compSid = officeCd;
    }
    config.params.officeSid = officeSid;
    config.params.isGetClient = isClient;
    config.params.reqComReferenceDate = utcDate;
    if (isAvailableBillingClosing !== void 0) {
      config.params.isAvailableBillingClosing = isAvailableBillingClosing;
    }

    // console.debug("GetParameter getRelatedBiz Url", isClient, config);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.MST_RELATEDBIZ, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // console.debug("GetParameter getRelatedBiz Response", isClient, jsonData);
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            const list = [];
            jsonData.resIdv.relatedPartys.forEach((row) => {
              if (isClient == "1") {
                // 取引先
                list.push({
                  text: `${row.clientCd}` + `（${row.relatedPartyName}）`,
                  value: row.relatedPartySid,
                  name: row.relatedPartyName,
                  clientSid: row.compSid,
                  clientCd: row.clientCd,
                });
              } else {
                list.push({
                  displayText:
                    commonUtil.omit(row.baseCd, appConfig.CONSTANT.BASE_CD_DISPLAY_LENGTH) +
                    `（${row.relatedPartyName}）`,
                  text: `${row.baseCd}` + `（${row.relatedPartyName}）`,
                  value: row.relatedPartySid,
                  name: row.relatedPartyName,
                  clientSid: row.clientSid,
                  baseCd: row.baseCd,
                });
              }
            });
            resolve(list);
            // エラー時
          } else {
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameter getRelatedBiz Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * 業務利用関係先マスタ取得(HT画面制御マスタ使用)
   */
  getRelatedBizAuthority(isClient, utcDate, authority, isAvailableBillingClosing, officeCd) {
    const config = httpClient.createGetApiRequestConfig();
    if (isClient == "0") {
      config.params.compSid = officeCd;
    }
    config.params.officeSid = sessionStorage.getItem("sales_office_sid");
    config.params.isGetClient = isClient;
    config.params.reqComReferenceDate = utcDate;
    if (isAvailableBillingClosing !== void 0) {
      config.params.isAvailableBillingClosing = isAvailableBillingClosing;
    }
    config.params.authority = authority;

    // console.debug("GetParameter getRelatedBiz Url", isClient, config);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.MST_RELATEDBIZ_AUTHORITY, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // console.debug("GetParameter getRelatedBiz Response", isClient, jsonData);
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            const list = [];
            jsonData.resIdv.relatedPartys.forEach((row) => {
              if (isClient == "1") {
                // 取引先
                list.push({
                  text: `${row.clientCd}` + `（${row.relatedPartyName}）`,
                  value: row.relatedPartySid,
                  name: row.relatedPartyName,
                  clientSid: row.compSid,
                  clientCd: row.clientCd,
                });
              } else {
                list.push({
                  displayText:
                    commonUtil.omit(row.baseCd, appConfig.CONSTANT.BASE_CD_DISPLAY_LENGTH) +
                    `（${row.relatedPartyName}）`,
                  text: `${row.baseCd}` + `（${row.relatedPartyName}）`,
                  value: row.relatedPartySid,
                  name: row.relatedPartyName,
                  clientSid: row.clientSid,
                  baseCd: row.baseCd,
                });
              }
            });
            resolve(list);
            // エラー時
          } else {
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameter getRelatedBiz Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * 業務利用関係先マスタ取得
   */
  getRelatedBizPartialMatch(
    isClient,
    utcDate,
    isAvailableBillingClosing,
    officeCd,
    baseCd,
    officeSid = sessionStorage.getItem("sales_office_sid")
  ) {
    const config = httpClient.createGetApiRequestConfig();
    if (isClient == "0") {
      config.params.compSid = officeCd;
    }
    config.params.officeSid = officeSid;
    config.params.isGetClient = isClient;
    config.params.reqComReferenceDate = utcDate;
    config.params.baseCd = baseCd;
    if (isAvailableBillingClosing !== void 0) {
      config.params.isAvailableBillingClosing = isAvailableBillingClosing;
    }

    // console.debug("GetParameter getRelatedBiz Url", isClient, config);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.MST_RELATEDBIZ_PARTIAL_MATCH, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // console.debug("GetParameter getRelatedBiz Response", isClient, jsonData);
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            const list = [];
            jsonData.resIdv.relatedPartys.forEach((row) => {
              if (isClient == "1") {
                // 取引先
                list.push({
                  text: `${row.clientCd}` + `（${row.relatedPartyName}）`,
                  value: row.relatedPartySid,
                  name: row.relatedPartyName,
                  clientSid: row.compSid,
                  clientCd: row.clientCd,
                });
              } else {
                list.push({
                  displayText:
                    commonUtil.omit(row.baseCd, appConfig.CONSTANT.BASE_CD_DISPLAY_LENGTH) +
                    `（${row.relatedPartyName}）`,
                  text: `${row.baseCd}` + `（${row.relatedPartyName}）`,
                  value: row.relatedPartySid,
                  name: row.relatedPartyName,
                  clientSid: row.clientSid,
                  baseCd: row.baseCd,
                });
              }
            });
            resolve(list);
            // エラー時
          } else {
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameter getRelatedBiz Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * 業務利用ユーザー関係先マスタ取得
   */
  getUserdBizPartialMatch(
    userClientSid,
    clientCd,
    // isAvailableBillingClosing,
    officeSid = sessionStorage.getItem("sales_office_sid"),
    sortingMst = "02"
  ) {
    const config = httpClient.createGetApiRequestConfig();
    config.params.userClientSid = userClientSid;
    config.params.clientCd = clientCd;
    config.params.officeSid = officeSid;
    config.params.sortingMst = sortingMst;

    // console.debug("GetParameter getRelatedBiz Url", userClientSid, config);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.BIZ_DELIVERY_USER_PRODUCT, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // console.debug("GetParameter getRelatedBiz Response", userClientSid, jsonData);
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            const list = [];
            jsonData.resIdv.userManufacturer.forEach((row) => {
              // if (userClientSid == "1") {
              // 取引先
              list.push({
                text: `${row.makerClientCd}` + `（${row.makerName}）`,
                value: row.makerClientCd,
                name: row.makerName,
                clientSid: row.clientSid,
              });
              // } else {
              //   list.push({
              //     text: `${row.baseCd}` + `（${row.makerName}）`,
              //     value: row.makerClientCd,
              //     name: row.makerName,
              //   });
              // }
            });
            resolve(list);
            // エラー時
          } else {
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameter getRelatedBiz Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * 業務利用関係先マスタ取得(バーコードマスタ用)
   */
  getRelatedBizBarcode(officeSid = sessionStorage.getItem("sales_office_sid")) {
    const config = httpClient.createGetApiRequestConfig();
    config.params.officeSid = officeSid;

    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.MST_RELATEDBIZ_BARCODE, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            const list = [];
            jsonData.resIdv.relatedPartys.forEach((row) => {
              // 取引先
              list.push({
                text: `${row.clientCd}` + `（${row.relatedPartyName}）`,
                value: row.relatedPartySid,
                name: row.relatedPartyName,
                clientSid: row.compSid,
                clientCd: row.clientCd,
              });
            });
            resolve(list);
            // エラー時
          } else {
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameter getUserdBizBarcode Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * コードマスタ取得
   */
  getCodeMst(codeType) {
    // httpClientで使用する共通IFパラメータ等を作成する。
    const config = httpClient.createGetApiRequestConfig();

    // 検索条件などのパラメータを設定する
    config.params.isMst = "0";
    config.params.codeType = codeType;

    // console.debug("GetParameter getCodeMst Url", codeType, config);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.MST_CODE, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // console.debug("GetParameter getCodeMst Response", codeType, jsonData);

          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            const list = [];
            jsonData.resIdv.codeDefinition.forEach((row) => {
              list.push({
                text: row.codeInfoList[0].codeName,
                value: row.codeInfoList[0].code,
                name: row.codeInfoList[0].codeName,
              });
            });
            resolve(list);
            // エラー時
          } else {
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameter getCodeMst Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * 業務利用商品マスタ取得
   */
  getItemsBiz(clientSid, utcDate) {
    const config = httpClient.createGetApiRequestConfig();

    config.params.officeSid = sessionStorage.getItem("sales_office_sid");
    config.params.clientSid = clientSid;
    config.params.reqComReferenceDate = utcDate;

    // console.debug("GetParameter getItemsBiz Url", clientSid, config);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.MST_ITEMBIZ, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // console.debug("GetParameter getItemsBiz Response", clientSid, jsonData);
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            const list = [];
            jsonData.resIdv.ItemsBizUse.forEach((row) => {
              list.push({
                text: `${row.itemCd}` + `（${row.itemName}）`,
                value: row.itemStandardSid,
                name: row.itemName,
                code: row.itemCd,
                quantity: row.inboundUnitQuantity,
                isNew: false,
              });
            });
            resolve(list);
            // エラー時
          } else {
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameter getItemsBiz Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * 業務利用商品マスタ取得
   */
  getItemsBizAddName(clientSid, utcDate, itemName, isMain) {
    const config = httpClient.createGetApiRequestConfig();
    config.params.officeSid = sessionStorage.getItem("sales_office_sid");
    config.params.clientSid = clientSid;
    config.params.reqComReferenceDate = utcDate;
    config.params.itemName = itemName;
    config.params.isMain = isMain == "1" ? isMain : "";
    // console.debug("GetParameter getItemsBizAddName Url", clientSid, config);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.MST_ITEMBIZ, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // console.debug("GetParameter getItemsBizAddName Response", clientSid, jsonData);
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            const list = [];
            jsonData.resIdv.ItemsBizUse.forEach((row) => {
              list.push({
                text: `${row.itemCd}` + `（${row.itemName}）`,
                value: row.itemStandardSid,
                name: row.itemName,
                code: row.itemCd,
                quantity: row.inboundUnitQuantity,
                isNew: false,
                validFrom: row.validFrom,
                validTo: row.validTo,
              });
            });
            resolve(list);
            // エラー時
          } else {
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameter getItemsBizAddName Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * 業務利用商品マスタ2取得
   */
  getItemsBizAddName2(clientSid, utcDate, itemName, isMain) {
    const config = httpClient.createGetApiRequestConfig();
    config.params.officeSid = sessionStorage.getItem("sales_office_sid");
    config.params.clientSid = clientSid;
    config.params.reqComReferenceDate = utcDate;
    config.params.itemName = itemName;
    config.params.isMain = isMain == "1" ? isMain : "";
    // console.debug("GetParameter getItemsBizAddName Url", clientSid, config);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.MST_ITEMBIZ2, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // console.debug("GetParameter getItemsBizAddName Response", clientSid, jsonData);
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            const list = [];
            jsonData.resIdv.ItemsBizUse.forEach((row) => {
              var itemCd = row.itemCd.substring(0, 50).trim();
              var incidental = row.itemCd.slice(-50).trim();
              list.push({
                text: `${itemCd}` + ` ${incidental}` + `（${row.itemName}）`,
                value: row.itemStandardSid,
                name: row.itemName,
                code: row.itemCd,
                quantity: row.inboundUnitQuantity,
                isNew: false,
                warehouseSid: row.warehouseSid,
                locationSid: row.locationSid,
                validFrom: row.validFrom,
                validTo: row.validTo,
                serialFlg: row.serialFlg,
                lotFlg: row.lotFlg,
                dueDateFlg: row.dueDateFlg,
                lotSubkey1Flg: row.lotSubkey1Flg,
                lotSubkey2Flg: row.lotSubkey2Flg,
              });
            });
            resolve(list);
            // エラー時
          } else {
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameter getItemsBizAddName Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * 取引先営業所別在庫一覧照会画面での業務利用商品マスタ2取得
   */
  getItemsBizAddName2ForStockInquery(clientSid, utcDate, itemName, officeSid, isMain) {
    const config = httpClient.createGetApiRequestConfig();
    config.params.officeSid = officeSid;
    config.params.clientSid = clientSid;
    config.params.reqComReferenceDate = utcDate;
    config.params.itemName = itemName;
    config.params.isMain = isMain == "1" ? isMain : "";
    // console.debug("GetParameter getItemsBizAddName Url", clientSid, config);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.MST_ITEMBIZ_FOR_STOCK_BY_CLIENT, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // console.debug("GetParameter getItemsBizAddName Response", clientSid, jsonData);
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            const list = [];
            jsonData.resIdv.ItemsBizUse.forEach((row) => {
              var itemCd = row.itemCd.substring(0, 50).trim();
              var incidental = row.itemCd.slice(-50).trim();
              list.push({
                text: `${itemCd}` + ` ${incidental}` + `（${row.itemName}）`,
                value: row.itemStandardSid,
                name: row.itemName,
                code: row.itemCd,
                quantity: row.inboundUnitQuantity,
                isNew: false,
                warehouseSid: row.warehouseSid,
                locationSid: row.locationSid,
                validFrom: row.validFrom,
                validTo: row.validTo,
                serialFlg: row.serialFlg,
                lotFlg: row.lotFlg,
              });
            });
            resolve(list);
            // エラー時
          } else {
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameter getItemsBizAddName Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * 業務利用商品マスタ2取得
   */
  getUserItemsBizAddName(clientSid, itemCd) {
    const config = httpClient.createGetApiRequestConfig();
    config.params.clientSid = clientSid;
    config.params.itemCd = itemCd;
    // console.debug("GetParameter getItemsBizAddName Url", clientSid, config);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.BIZ_DELIVERY_USER_PRODUCT_CD, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // console.debug("GetParameter getItemsBizAddName Response", clientSid, jsonData);
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            const list = [];
            jsonData.resIdv.userItemsBizUse.forEach((row) => {
              list.push({
                text: `${row.userItemCd}` + `（${row.userItemName}）`,
                value: row.userItemSid,
                name: row.userItemName,
                code: row.userItemCd,
                baseCd: row.userItemBaseCd.substring(0, 50).trim(),
                isNew: false,
              });
            });
            resolve(list);
            // エラー時
          } else {
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameter getItemsBizAddName Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * 取引先営業所別在庫一覧照会画面用の業務利用商品マスタ2取得
   */
  getUserItemsBizAddNameForStockByClient(isStockByClient, itemCd) {
    const config = httpClient.createGetApiRequestConfig();
    config.params.isStockByClient = isStockByClient;
    config.params.itemCd = itemCd;
    // console.debug("GetParameter getItemsBizAddName Url", clientSid, config);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.BIZ_DELIVERY_USER_PRODUCT_CD, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // console.debug("GetParameter getItemsBizAddName Response", clientSid, jsonData);
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            const list = [];
            jsonData.resIdv.userItemsBizUse.forEach((row) => {
              list.push({
                text: `${row.userItemCd}` + `（${row.userItemName}）`,
                value: row.userItemSid,
                name: row.userItemName,
                code: row.userItemCd,
                baseCd: row.userItemBaseCd.substring(0, 50).trim(),
                isNew: false,
              });
            });
            resolve(list);
            // エラー時
          } else {
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameter getItemsBizAddName Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * 付帯品番情報取得（ユーザー品番マスタ）
   */
  getIncidentalItemsBizAddName(clientSid, itemCd, userItemBaseCd) {
    const config = httpClient.createGetApiRequestConfig();
    config.params.clientSid = clientSid;
    config.params.itemCd = itemCd;
    config.params.userItemBaseCd = userItemBaseCd;
    // console.debug("GetParameter getIncidentalItemsBizAddName Url", clientSid, config);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.BIZ_INCIDENTAL_ITEMS, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // console.debug("GetParameter getIncidentalItemsBizAddName Response", clientSid, jsonData);
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            const list = [];
            jsonData.resIdv.userItemsBizUse.forEach((row) => {
              if (row.incidentalItemCd != "") {
                list.push({
                  text: row.incidentalItemCd,
                  value: row.incidentalItemCd,
                  isNew: false,
                });
              }
            });
            resolve(list);
            // エラー時
          } else {
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameter getIncidentalItemsBizAddName Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * 付帯情報取得
   */
  getItemIndividual(itemCommonSid) {
    // httpClientで使用する共通IFパラメータ等を作成する。
    const config = httpClient.createGetApiRequestConfig();

    config.params.itemCommonSid = itemCommonSid;
    config.params.isMst = "0";

    // console.debug("GetParameter getItemIndividual Url", itemCommonSid, config);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.MST_ITEMSINDIVI, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // console.debug("GetParameter getItemIndividual Response", itemCommonSid, jsonData);

          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            const list = [];
            jsonData.resIdv.individuals.forEach((row) => {
              list.push({
                text: row.individualLanguage[0].subInformation,
                value: row.individualLanguage[0].subInformationComment,
                required: "1",
              });
            });
            resolve(list);

            // エラー時
          } else {
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameter getItemIndividual Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * 業務利用ユーザ基本品番取得
   */
  getUserItemBaseCd(clientSid, userItemBaseCd) {
    // httpClientで使用する共通IFパラメータ等を作成する。
    const config = httpClient.createGetApiRequestConfig();

    config.params.clientSid = clientSid;
    config.params.userItemBaseCd = userItemBaseCd;

    // console.debug("GetParameter getUserItemBaseCd Url", clientSid, config);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.BIZ_USER_ITEM_BASE_CD, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // console.debug("GetParameter getUserItemBaseCd Response", clientSid, jsonData);

          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            const list = [];
            jsonData.resIdv.userItemsBaseCd.forEach((row) => {
              list.push({
                text: row.userItemBaseCd,
                value: row.userItemBaseCd,
                name: row.userItemBaseCd,
              });
            });
            resolve(list);
            // エラー時
          } else {
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameter getUserItemBaseCd Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * 単位マスタ取得
   */
  getUnitCdList(isMst) {
    const config = httpClient.createGetApiRequestConfig();

    config.params.isMst = isMst;

    // console.debug("GetParameter getUnitCdList Url", isMst, config);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.MST_UNIT, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // console.debug("GetParameter getUnitCdList Response", isMst, jsonData);
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            let list = [{ text: "", name: "", value: "" }];
            jsonData.resIdv.unit.forEach((row) => {
              list.push({
                text: `${row.unitCd}` + `（${row.unitCdName}）`,
                value: row.unitCd,
                name: row.unitCdName,
              });
            });
            resolve(list);
            // エラー時
          } else {
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameter getUnitCdList Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }
  /**
   * G業務仕様マスタ取得
   */
  getBizSpecList(isMst, clientSid) {
    const config = httpClient.createGetApiRequestConfig();
    config.params.officeSid = sessionStorage.getItem("sales_office_sid");
    config.params.clientSid = clientSid;
    config.params.isMst = isMst;

    // console.debug("GetParameter getBizSpecList Url", isMst, config);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.MST_BIZSPEC, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // console.debug("GetParameter getBizSpecList Response", isMst, jsonData);
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            let list = [{ text: "", name: "", value: "" }];
            jsonData.resIdv.bizSpec.forEach((row) => {
              list.push({
                text: `${row.businessNo}` + `（${row.gggnak}）`,
                value: row.businessNo,
                name: row.gggnak,
              });
            });
            resolve(list);
            // エラー時
          } else {
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameter getBizSpecList Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * 単価CDマスタ取得
   */
  getUnitPriceCdList(isMst) {
    const config = httpClient.createGetApiRequestConfig();

    config.params.isMst = isMst;

    // console.debug("GetParameter getUnitPriceCdList Url", isMst, config);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.MST_UNITPRICE_CD, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // console.debug("GetParameter getUnitPriceCdList Response", isMst, jsonData);
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            let list = [{ text: "", name: "", value: "" }];
            jsonData.resIdv.unitPriceCd.forEach((row) => {
              list.push({
                text: `${row.unitPriceCd}` + `（${row.unitPriceCdName}）`,
                value: row.unitPriceCd,
                name: row.unitPriceCdName,
              });
            });
            resolve(list);
            // エラー時
          } else {
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameter getUnitPriceCdList Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * 単価Grマスタ取得
   */
  getUnitPriceGrList(isMst) {
    const config = httpClient.createGetApiRequestConfig();

    config.params.isMst = isMst;

    // console.debug("GetParameter getUnitPriceGrList Url", isMst, config);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.MST_UNITPRICE_GROUP, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // console.debug("GetParameter getUnitPriceGrList Response", isMst, jsonData);
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            let list = [{ text: "", name: "", value: "" }];
            jsonData.resIdv.unitPriceGroup.forEach((row) => {
              list.push({
                text: `${row.unitPriceGroup}` + `（${row.unitPriceGroupName}）`,
                value: row.unitPriceGroup,
                name: row.unitPriceGroupName,
              });
            });
            resolve(list);
            // エラー時
          } else {
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameter getUnitPriceGrList Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * G請求項目マスタ取得
   */
  getInvoiceItemList(isMst, clientSid, businessNo, gkCode) {
    const config = httpClient.createGetApiRequestConfig();
    config.params.officeSid = sessionStorage.getItem("sales_office_sid");
    config.params.clientSid = clientSid;
    config.params.businessNo = businessNo;
    if (gkCode !== void 0) {
      config.params.gkcode = gkCode;
    }
    config.params.isMst = isMst;

    // console.debug("GetParameter getInvoiceItemList Url", isMst, config);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.MST_INVOICE_ITEM, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // console.debug("GetParameter getInvoiceItemList Response", isMst, jsonData);
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            let list = [{ text: "", name: "", value: "" }];
            jsonData.resIdv.invoiceItem.forEach((row) => {
              if (gkCode !== void 0) {
                list.push({
                  text: row.gkkamo,
                  value: row.invoiceItemSid,
                  name: row.gkkamona,
                  key: row.gkcode,
                  officeSid: row.officeSid,
                  officeCd: row.officeCd,
                  officeName: row.officeName,
                  warehouseSid: row.warehouseSid,
                  warehouseCd: row.warehouseCd,
                  warehouseName: row.warehouseName,
                  gkritu: row.gkritu,
                });
              } else {
                list.push({
                  text: `${row.gkcode}` + `（${row.gkkona}）`,
                  value: row.gkcode,
                  name: row.gkkona,
                  sid: row.invoiceItemSid,
                  officeSid: row.officeSid,
                  officeCd: row.officeCd,
                  officeName: row.officeName,
                  warehouseSid: row.warehouseSid,
                  warehouseCd: row.warehouseCd,
                  warehouseName: row.warehouseName,
                  gkritu: row.gkritu,
                });
              }
            });
            resolve(list);
            // エラー時
          } else {
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameter getInvoiceItemList Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * 最大月度取得API
   */
  getMaxMonth(clientSid) {
    const config = httpClient.createGetApiRequestConfig();

    // ★必須パラメータ
    config.params.clientSid = clientSid;

    // console.debug("GetParameter getMaxMonth url", config);

    return new Promise((resolve, reject) => {
      httpClient
        .secureGet(appConfig.API_URL.BIZ_BIL_INIT_MONTH, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // console.debug("GetParameter getMaxMonth response", jsonData);
          // 正常時
          let list = {};
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            let yyyyMm = "";
            let periodDiv = "";
            if (jsonData.resIdv.periodStockYyyyMm != null) {
              yyyyMm = jsonData.resIdv.periodStockYyyyMm.yyyyMm;
              periodDiv = jsonData.resIdv.periodStockYyyyMm.periodDiv;
            }
            list = { yyyyMm: yyyyMm, periodDiv: periodDiv };
            resolve(list);
          } else {
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch(() => {
          console.error("GetParameter getMaxMonth Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  getUserBiz(authorizerList) {
    // 担当者
    const config = httpClient.createGetApiRequestConfig();
    config.params.searchCategoryDiv = "0";
    if (authorizerList != null) {
      config.params.authorizerList = authorizerList;
    }

    // console.debug("getParameter getUserBiz Url", config);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.MST_USERBIZ, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // console.debug("getParameter getUserBiz Response", response, jsonData);

          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            const list = [];
            jsonData.resIdv.users.forEach((row) => {
              list.push({
                text: row.userName,
                value: row.userSid,
              });
            });
            resolve(list);
          } else {
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((ex) => {
          console.error("GetParameter getUserBiz Err", ex);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  getWarehouseBiz() {
    // 倉庫
    const config = httpClient.createGetApiRequestConfig();
    config.params.officeSid = sessionStorage.getItem("sales_office_sid");
    config.params.searchCategory = "0";
    config.params.isMst = "0";

    // console.debug("GetParameter getWarehouseBiz Url", config);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.MST_WAREHOUSEBIZ_USESEARCH, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // console.debug("GetParameter getWarehouseBiz Response", response, jsonData);
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            const list = [];
            jsonData.resIdv.warehouses.forEach((row) => {
              list.push({
                text: row.warehouseLanguage[0].warehouseName,
                value: row.warehouseLanguage[0].warehouseSid,
                name: row.warehouseLanguage[0].warehouseName,
              });
            });

            resolve(list);
          } else {
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((ex) => {
          console.error("GetParameter getWarehouseBiz Err", ex);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  getLocationBizUse(params) {
    // ロケ
    const config = httpClient.createGetApiRequestConfig();
    Object.assign(config.params, params);

    // 検索区分
    if (!config.params.searchCategoryDiv) {
      config.params.searchCategoryDiv = "0";
    }
    // 処理区分
    if (!config.params.processDiv) {
      config.params.processDiv = "01";
    }

    // console.debug("GetParameter getLocationBizUse Url", config);
    return new Promise((resolve, reject) => {
      httpClient
        .secureGet(appConfig.API_URL.MST_LOCATIONBIZ, config)
        .then((response) => {
          // console.debug("GetParameter getLocationBiz Response", response);
          const jsonData = JSON.parse(JSON.stringify(response.data));

          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            const list = [];
            jsonData.resIdv.locations.forEach((row) => {
              if (params.processDiv == "02") {
                list.push({
                  text: params.groupNm + row.locationGroup,
                  value: row.locationGroup,
                });
              } else {
                list.push({
                  text: row.locationNo,
                  value: row.locationSid,
                  name: row.locationNo,
                  validFrom: row.validFrom,
                  validTo: row.validTo,
                });
              }
            });

            resolve(list);
          } else {
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((ex) => {
          console.error("GetParameter getLocationBiz Err", ex);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * PC画面制御取得API
   */
  getFunctionPermissions(params) {
    const config = httpClient.createGetApiRequestConfig();
    config.params.officeSid = sessionStorage.getItem("sales_office_sid");
    Object.assign(config.params, params);
    return new Promise((resolve, reject) => {
      httpClient
        .secureGet(appConfig.API_URL.MST_FUNCTION_PERMISSIONS, config)
        .then((response) => {
          // console.debug("GetParameter getFunctionPermissions Response", response);
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            resolve(jsonData.resIdv.functionPermissions);
          } else {
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((ex) => {
          console.error("GetParameter getFunctionPermissions Err", ex);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * バーコード種類取得
   */
  getBcrType(params) {
    const config = httpClient.createGetApiRequestConfig();
    Object.assign(config.params, params);
    // console.debug("GetParameter getBcrType Url", config);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.INI_BCR_TYPE_LIST, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // console.debug("GetParameter getBcrType Response", jsonData);
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            const list = [];
            jsonData.resIdv.bcrTypeList.forEach((row) => {
              list.push({
                bcrType: row.bcrType,
                codeName: row.codeName,
                remarks: row.remarks,
              });
            });
            resolve(list);
          } else {
            // エラー時
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameter getBcrType Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * バーコード項目取得
   */
  getBcrItem(params) {
    const config = httpClient.createGetApiRequestConfig();
    Object.assign(config.params, params);
    // console.debug("GetParameter getBcrItem Url", config);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.INI_BCR_ITEM_LIST, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // console.debug("GetParameter getBcrItem Response", jsonData);
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            const list = [];
            jsonData.resIdv.bcrItemList.forEach((row) => {
              list.push({
                bcrItem: row.bcrItem,
                codeName: row.codeName,
                remarks: row.remarks,
                item01: row.item01,
                item02: row.item02,
                item03: row.item03,
                item04: row.item04,
                item05: row.item05,
              });
            });
            resolve(list);
          } else {
            // エラー時
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameter getBcrItem Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * 営業所別モジュール登録状況取得
   */
  getOfficeModule(params) {
    const config = httpClient.createGetApiRequestConfig();
    Object.assign(config.params, params);
    // console.debug("GetParameter getOfficeModule Url", config);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.INI_OFFICE_MODULE, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // console.debug("GetParameter getOfficeModule Response", jsonData);
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            resolve(jsonData.resIdv.officeModuleList);
          } else {
            // エラー時
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameter getOfficeModule Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * 業所モジュール別登録状況取得
   */
  getOfficeModuleInfo(params) {
    const config = httpClient.createGetApiRequestConfig();
    Object.assign(config.params, params);
    // console.debug("GetParameter getOfficeModuleInfo Url", config);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.INI_OFFICE_MODULE_INFO, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // console.debug("GetParameter getOfficeModuleInfo Response", jsonData);
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            resolve(jsonData.resIdv.officeModuleInfoList);
          } else {
            // エラー時
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameter getOfficeModuleInfo Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * 工程
   */
  getProc(params) {
    const config = httpClient.createGetApiRequestConfig();
    Object.assign(config.params, params);
    // console.debug("GetParameter getProc Url", config);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.MST_INSPECTION_OFFICE, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // console.debug("GetParameter getProc Response", jsonData);
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            resolve(jsonData.resIdv.proc);
          } else {
            // エラー時
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameter getProc Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * マスタテンプレートダウンロード処理
   */
  getDownloadMstTempleteFile(params) {
    const config = httpClient.createGetApiRequestConfig();
    Object.assign(config.params, params);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.INI_MASTER_TEMPLETE_FILE_DOWMLOAD_URL, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          if (jsonData.resCom.resComCode == "000") {
            resolve(jsonData.resIdv.url);
          } else {
            // エラー時
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameter getDownloadMstTempleteFile Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * キュー状況取得処理
   */
  getMstQueInfo(params) {
    const config = httpClient.createGetApiRequestConfig();
    Object.assign(config.params, params);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.BIZ_QUE_INFO, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            resolve(response);
          } else {
            // エラー時
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameter getMstQueInfo Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * 拠点マスタ情報取得
   */
  getTmsBizBaseMst(params) {
    const config = httpClient.createGetApiRequestConfig();
    Object.assign(config.params, params);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.MST_BIZ_BASE_SEARCH, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            const list = [];
            jsonData.resIdv.baseInfoList.forEach((row) => {
              list.push({
                text: `${row.baseCode}` + `（${row.baseName}）`,
                value: row.baseSid,
                name: row.baseName,
                customerCode: row.customerCode,
                postCd: row.postCode,
                address: `${row.address1}` + `${row.address2}`,
                telephoneNumber:
                  `${row.telephoneNumber == null ? "" : row.telephoneNumber}` +
                  `${row.telephoneNumber2 == null ? "" : row.telephoneNumber2}`,
                faxNumber: row.faxNumber,
              });
            });
            resolve(list);
          } else {
            // エラー時
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameter getTmsBizBaseMst Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * 取引先マスタ情報取得
   */
  getTmsBizCustomerMst(params) {
    const config = httpClient.createGetApiRequestConfig();
    Object.assign(config.params, params);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.MST_CUSTOMERS_SEARCH, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            const list = [];
            jsonData.resIdv.supplierInfoList.forEach((row) => {
              list.push({
                text: `${row.customerCode}` + `（${row.customerName}）`,
                value: row.customerCode,
                name: row.customerName,
                customerType: row.customerType,
                isDeliveryCompany: row.isDeliveryCompany,
                isReceivable: row.isReceivable,
                isPayable: row.isPayable,
                postCd: row.postCode,
                address: `${row.address1}` + `${row.address2}`,
                telephoneNumber:
                  `${row.telephoneNumber == null ? "" : row.telephoneNumber}` +
                  `${row.telephoneNumber2 == null ? "" : row.telephoneNumber2}`,
              });
            });
            resolve(list);
          } else {
            // エラー時
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameter getTmsBizCustomerMst Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * コードマスタ(TMS)取得
   */
  getTmsCodeMst(params) {
    const config = httpClient.createGetApiRequestConfig();
    Object.assign(config.params, params);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.MST_CODE_SEARCH, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            const list = [];
            jsonData.resIdv.codeMstInfoList.forEach((row) => {
              row.codeList.forEach((val) => {
                list.push({
                  value: val.code,
                  text: val.displayName,
                  name: val.displayName,
                  codeType: row.codeType,
                  codeTypeDisplayName: row.codeTypeDisplayName,
                });
              });
            });
            resolve(list);
          } else {
            // エラー時
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameter getTmsCodeMst Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * 業務マスタ取得
   */
  getOperationMst(params) {
    const config = httpClient.createGetApiRequestConfig();
    Object.assign(config.params, params);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.MST_OPERATION_SEARCH, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            const list = [];
            jsonData.resIdv.operationInfoList.forEach((row) => {
              list.push({
                text: `${row.operationNumber}` + `（${row.operationName.trim()}）`,
                value: row.operationNumber,
                name: row.operationName.trim(),
                operationSid: row.operationSid,
                officeCode: row.officeCode,
                requesterCustomerCode: row.requesterCustomerCode,
                shipperBillingCounter: row.shipperBillingCounter,
                billingAddress: `${row.billingAddress1.trim()}` + `${row.billingAddress2.trim()}`,
                postCode: row.postCode,
                telephoneNumber: row.telephoneNumber,
                paymentTerms: row.paymentTerms,
                customerCutoffDay: row.customerCutoffDay,
                paymentDueDay: row.paymentDueDay,
                invoiceFormat: row.invoiceFormat,
                shipperAbbrCode: row.shipperAbbrCode,
                consignor: row.consignor,
                consignee: row.consignee,
              });
            });
            resolve(list);
          } else {
            // エラー時
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameter getTmsCodeMst Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }
}
export const getParameter = new GetParameter();
